<template>
    <!-- 收货地址 -->
    <div class="customer_box">
        <div class="customer_search_box">
            <div class="search-item">

            </div>
            <div class="search-item">

            </div>
            <div class="btn_box">
                <el-button class="btn_item message_btn" size="small" plain @click="buildAddress('add')">新建收货地址</el-button>
            </div>
        </div>
        <div class="table_mode">
            <el-table :data="tableData" v-loading="loading" @sort-change="handleSortChange" stripe style="width: 100%">
                <el-table-column prop="address" label="收货地址" min-width="140" :show-overflow-tooltip="true" align="center">
                    <template slot-scope="scope">
                        <span>{{scope.row.province}}{{scope.row.city}}{{scope.row.area}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="address" label="详细地址" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column prop="contacter" label="收货人" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column prop="mobile" label="收货电话" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column prop="proofImg" label="图片" min-width="90" align="center">
                    <template slot-scope="scope">
                        <el-image v-if="scope.row.proofImg!='--'" ref="viewer" style="width: 100px; height: 100px" :fit="'cover'" :src="scope.row.proofImg" :preview-src-list="srcList">
                        </el-image>
                        <span v-else>--</span>
                    </template>
                </el-table-column>
                <el-table-column prop="amount" label="是否默认" min-width="80" :show-overflow-tooltip="true" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.isDefault==1">是</span>
                        <span v-if="scope.row.isDefault==0">否</span>
                    </template>
                </el-table-column>
                <el-table-column prop="statusTitle" label="审核状态" :show-overflow-tooltip="true" align="center">
                </el-table-column>
                <el-table-column prop="reason" label="审核备注" :show-overflow-tooltip="true" align="center">
                </el-table-column>
                <el-table-column prop="address" fixed="right" label="操作 " width="200" align="center">
                    <template slot-scope="scope">
                        <div class="table_btn">
                            <el-popconfirm title="确定要将此收货地址设置为默认吗？" @onConfirm="setClick(scope.row.id)" v-if="scope.row.statusTitle=='审核通过'&&scope.row.isDefault==0">
                                <div class="btn_item" slot="reference" v-if="scope.row.statusTitle=='审核通过'&&scope.row.isDefault==0">设为默认</div>
                            </el-popconfirm>
                            <div class="btn_item" v-if="scope.row.statusTitle=='待审核'||$store.state.memberInfo.memberAttr==1" @click="editClick(scope.row)">编辑</div>
                            <el-popconfirm icon="el-icon-error" icon-color="red" title="删除后将不可恢复，确定要操作吗？" @onConfirm="delClick(scope.row.id)">
                                <div class="btn_item" slot="reference">删除</div>
                            </el-popconfirm>
                        </div>
                    </template>
                </el-table-column>
                <div slot="empty" class="nodata_box">
                    <img src="@/assets/images/index/nodata.png" />
                    <span class="txt">暂无数据哦~</span>
                </div>
            </el-table>
        </div>
        <el-dialog :title="addressTitle" :visible.sync="addressDialog" :close-on-click-modal="false" width="30%" @closed="handleClose">
            <div v-if="$store.state.memberInfo.memberAttr!=1" class="alert"><i style="color:#ffaa00;margin-right:10px;" class="el-icon-warning"></i> <span>新增、编辑的收货地址需要后台审核通过后才可使用</span></div>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="收货地址" prop="provinceId">
                            <el-select style="margin-right:10px;width:155%;" v-model="ruleForm.provinceId" @change="provinceChange" placeholder="请选择省">
                                <el-option v-for="item in provinceOptions" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item prop="cityId">
                            <el-select style="margin-right:10px;left:-26px;width:155%;" v-model="ruleForm.cityId" @change="cityChange" placeholder="请选择市">
                                <el-option v-for="item in cityOptions" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item prop="areaId">
                            <el-select style="left:-52px;width:155%;" v-model="ruleForm.areaId" placeholder="请选择区">
                                <el-option v-for="item in areaOptions" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item label="详细地址" prop="address">
                    <el-input type="textarea" v-model="ruleForm.address" placeholder="请输入至少5个字符"></el-input>
                </el-form-item>
                <el-form-item label="收货人姓名" prop="contacter">
                    <el-input v-model="ruleForm.contacter" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="收货人电话" prop="mobile">
                    <el-input v-model="ruleForm.mobile" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="上传图片" :required="$store.state.memberInfo.memberAttr!=1">
                    <el-upload :class="{imgHide:imgHideUpload}" action="#" :auto-upload="false" :limit="1" :file-list="fileList" :on-change="onChange" list-type="picture-card" :on-preview="handlePictureCardPreview" :on-remove="handleRemove">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    请上传客户地址变更授权书
                    <el-dialog :visible.sync="dialogVisible" append-to-body>
                        <img width="100%" :src="dialogImageUrl" alt="">
                    </el-dialog>
                </el-form-item>
                <el-form-item label=" 默认地址" prop="isDefault" v-if="$store.state.memberInfo.memberAttr==1">
                    <el-switch v-model="ruleForm.isDefault" :active-value="1" :inactive-value="0"></el-switch>
                </el-form-item>

            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="resetForm">取 消</el-button>
                <el-button type="primary" @click="submitForm">确 定</el-button>
            </span>
        </el-dialog>
        <!-- <div class="pagination_box">
            <el-pagination background layout="prev, pager, next, sizes, total" :page-sizes="[10, 20, 30, 40]" :page-size.sync="filterinfo.pageSize" :current-page.sync="filterinfo.page" :total="total" @current-change="getAddressList" @size-change="getAddressList(1)">
            </el-pagination>
        </div> -->
    </div>
</template>

<script>
import { handlerTableData } from '@/utils/common'
export default {
    name: 'MyAddress',
    data() {
        return {
            loading: false,
            addressDialog: false,
            addressTitle: '新增地址',
            dialogImageUrl: '',
            srcList: [],
            dialogVisible: false,
            tableData: [],
            cityOptions: [],
            fileList: [],
            areaOptions: [],
            provinceOptions: [],
            imgHideUpload: false,
            imgLimitCount: 1,
            ruleForm: {
                provinceId: '',
                cityId: '',
                areaId: '',
                address: '',
                contacter: '',
                mobile: '',
                isDefault: 0,
                version: 110
            },
            rules: {
                contacter: [
                    { required: true, message: '请输入收货人姓名', trigger: 'blur' },
                    { max: 5, message: '不超过5个字符', trigger: 'blur' }
                ],
                mobile: [
                    { required: true, message: '请输入收货手机号', trigger: 'blur' },
                    { max: 11, message: '手机号不超过11位数字', trigger: 'blur' }
                ],
                provinceId: [
                    { required: true, message: '请选择省', trigger: 'blur' }

                ],
                cityId: [
                    { required: true, message: '请选择市', trigger: 'blur' }

                ],
                areaId: [
                    { required: true, message: '请选择区', trigger: 'blur' }

                ],
                address: [
                    { required: true, message: '请填写详细地址', trigger: 'blur' },
                    { min: 5, message: '至少输入5个字符', trigger: 'blur' }
                ]
            }
        };
    },
    created() {

    },
    mounted() {
        this.getAddressList()
    },
    methods: {
        // 点击
        async setClick(id) {
            const res = await this.$api.setDefaultAddress({ id })
            if (res.data.code === 200) {
                this.$message.success('设置成功')
                this.getAddressList()
            }
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
            this.ruleForm.proofImg = ''
            console.log(this.ruleForm);
            this.imgHideUpload = fileList.length >= this.imgLimitCount
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        async onChange(file, fileList) {
            const fd = new FormData()
            fd.append('licenseImg', file.raw)
            const res = await this.$api.subPic(fd)
            console.log(res.data.data.img);
            this.ruleForm.proofImg = res.data.data.img
            this.imgHideUpload = fileList.length >= this.imgLimitCount
        },
        async editClick(item) {
            this.addressTitle = '修改地址'
            this.getAddress(0, 'provinceOptions')
            if (item.proofImg != '--') {
                this.ruleForm.proofImg = item.proofImg
                this.fileList.push({ name: 'licenseImg', url: item.proofImg })
            }
            this.imgHideUpload = this.fileList.length >= this.imgLimitCount
            this.ruleForm.address = item.address
            this.ruleForm.contacter = item.contacter
            this.ruleForm.mobile = item.mobile
            this.ruleForm.provinceId = item.provinceId
            this.ruleForm.cityId = item.cityId
            this.ruleForm.areaId = item.areaId
            this.ruleForm.isDefault = item.isDefault
            this.getAddress(item.provinceId, 'cityOptions')
            this.getAddress(item.cityId, 'areaOptions')
            this.ruleForm.id = item.id
            this.addressDialog = true
            // const res = await this.$api.editAddress({ id })
            // if (res.data.code === 200) {
            //         this.getAddress(res.data.data.provinceId, 'cityOptions')
            //     this.getAddress(res.data.data.cityId, 'areaOptions')
            //     this.ruleForm = res.data.data

            // }
        },
        async delClick(id) {
            const res = await this.$api.deleteAddress({ id })
            if (res.data.code === 200) {
                this.$message.success('删除成功')
                this.getAddressList()
            }
        },
        submitForm() {
            console.log(this.ruleForm);
            if (this.$store.state.memberInfo.memberAttr != 1 && !this.ruleForm.proofImg) {
                this.$message.error('请上传图片')
                return
            }
            this.$refs['ruleForm'].validate(async (valid) => {
                if (valid) {
                    if (this.ruleForm.id) {
                        const res = await this.$api.editAddress(this.ruleForm)
                        console.log(this.ruleForm);
                        if (res.data.code === 200) {
                            this.resetForm()
                            this.$message.success('修改成功')
                            this.getAddressList()
                        }
                    } else {
                        const res = await this.$api.subAddress(this.ruleForm)
                        console.log(this.ruleForm);
                        if (res.data.code === 200) {
                            this.resetForm()
                            this.$message.success('添加成功')
                            this.getAddressList()
                        }
                    }

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        //清空数据
        resetForm() {
            this.$refs.ruleForm.resetFields();
            delete this.ruleForm.id

            this.ruleForm.proofImg = ''
            this.fileList = []
            this.addressDialog = false
        },
        handleClose() {
            this.resetForm()

        },
        buildAddress(type) {
            delete this.ruleForm.id
            this.ruleForm.proofImg = ''
            this.fileList = []
            this.addressTitle = '新增地址'
            this.getAddress(0, 'provinceOptions')
            this.addressDialog = true
            this.$nextTick(() => {
                this.$refs.ruleForm.resetFields();

            })
            this.imgHideUpload = this.fileList.length >= this.imgLimitCount
        },
        provinceChange() {
            this.getAddress(this.ruleForm.provinceId, 'cityOptions')
            this.ruleForm.cityId = ''
            this.ruleForm.areaId = ''
            this.cityOptions = []
            this.areaOptions = []
        },
        cityChange() {
            this.getAddress(this.ruleForm.cityId, 'areaOptions')
            this.ruleForm.areaId = ''
            this.areaOptions = []
        },
        //获取全国地址
        async getAddress(parentId, option) {
            const res = await this.$api.getAllAddress({ parentId })
            // if(res.data.code!=200) return this.$message.error(res.data.msg)
            this[option] = res.data.data
        },
        // 获取我的客户列表
        getAddressList(num) {
            let params = {
                prepareOrderNumber: "",
                memberId: 0
            }
            this.srcList = []
            this.loading = true
            this.$api.getAddress(params).then((res) => {
                this.loading = false
                if (res.data.code === 200) {
                    this.tableData = handlerTableData(res.data.data)
                    this.tableData.forEach(item => {
                        this.srcList.push(item.proofImg)
                    })
                    console.log(this.srcList);

                    // this.tableData = res.data.data.list
                    // this.options = res.data.data.shopType
                    // this.attributeOptions = res.data.data.attr
                }
            });
        },
        handleSortChange({ column, prop, order }) {
            if (order == 'ascending') {
                this.filterinfo.orderBy = "asc"
            } else {
                this.filterinfo.orderBy = "desc"
            }
            this.getAddressList()
        }
    }
};
</script>
<style lang="scss" >
.customer_box {
    position: relative;
    padding-top: 4px;
}
.message_btn {
    border: 1px solid #1a9dff;
    color: #1a9dff;
    &:hover {
        border: 1px solid #337cff;
        color: #337cff;
    }
}

.imgHide .el-upload--picture-card {
    display: none;
}
.alert {
    display: flex;
    align-items: center;
    // width: 600px;
    margin-left: 20px;
    // justify-content: center;
    border: 1px solid #fff3c6;
    background: #fffbe6;
    padding: 5px;
    margin-bottom: 20px;
}
</style>